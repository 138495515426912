import { computed } from "vue";
import { defineStore } from "pinia";

import { useAuthStore } from "./auth";
import { useCompanyStore } from "./company";

export const useUserContextStore = defineStore("userContext", () => {
  const authStore = useAuthStore();
  const companyStore = useCompanyStore();

  const which = computed(() => {
    if (authStore.selectedEmployee && authStore.selectedEmployee.company) {
      return "employee";
    } else if (companyStore.selectedCompanyId) {
      return "company";
    } else {
      return "unknown";
    }
  });

  const company = computed(() => {
    //const context = which.value;
    //console.log("which userContext", which.value);
    //if (which.value === "company") {
    return companyStore.selected;
    //} else if (which.value === "employee") {
    //  return authStore.selectedEmployee.company;
    //} else {
    //  return null;
    //}
  });

  return { which, company };
});
