import { Log } from "oidc-client-ts";

Log.setLogger(console);
Log.setLevel(Log.INFO);

const url = import.meta.env.VITE_FRONTEND_URL;

export const settings = {
	authority: import.meta.env.VITE_IDENTITY_BACKEND_URL,
	client_id: import.meta.env.VITE_IDENTITY_CLIENT_ID,
	redirect_uri: `${url}/callback`,
	post_logout_redirect_uri: url,
	response_type: "code",
	scope: "openid profile email urn:zitadel:iam:user:metadata",
	response_mode: "query",
	code_challenge_method: "S256",
	automaticSilentRenew: false,
	silent_redirect_uri: `${url}/silent-renew`,
	filterProtocolClaims: true,
};

export { Log };
