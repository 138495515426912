import { defineStore } from "pinia";
import { useStorage, timestamp } from "@vueuse/core";

import { useEmployeeStore } from "./employees";

// GraphQL
import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "../graphql/client";
import gql from "graphql-tag";

provideApolloClient(apolloClient);

export const useCompanyStore = defineStore("company", {
  state: () => ({
    companies: useStorage("companies", []),
    selected: useStorage("selectedCompany", {}),
    loading: false,
    lastChange: useStorage("companiesLastChange", null),
  }),
  getters: {
    allCompanies: (state) => state.companies,
    company: (state) => state.selected,
    selectedCompanyId: (state) => state.selected?.id || null,
    companyUsingCode: (state) => (companycode) => {
      return state.companies.find((c) => c.code === companycode);
    },
  },
  actions: {
    async getData(force = false) {
      // Quit if already loading
      if (this.loading) return;

      if (!force) {
        // Check if data is older than 5 minutes
        if (this.lastChange && timestamp() - this.lastChange < 300000) {
          return;
        }
      }

      this.loading = true;
      //console.log("Loading companies...");
      try {
        // use apollo client directly
        const result = await apolloClient.query({
          query: gql`
            query AppCompanies {
              companies(orderBy: { name: asc }) {
                id
                code
                name
                taxId
                rcsId
                vatId
                naceId
                personInChargeAccounting {
                  id
                  name
                  email
                  phone
                }
                personInChargeHumanResources {
                  id
                  name
                  email
                  phone
                }
              }
            }
          `,
        });
        //console.dir(result);
        if (result.data && result.data.companies) {
          if (result.data.companies.length > 0) {
            this.companies = result.data.companies;
            this.lastChange = timestamp();
            // set the first company as the selected company if only one company
            //if (this.companies.length === 1) {
            // auto-select the first company

            //}

            // Check if old selected company is still in the list, else select the first one
            if (this.selected.id) {
              const index = this.companies.findIndex((c) => c.id === this.selected.id);
              if (index === -1) {
                this.setCompany(this.companies[0]);
              }
            } else {
              this.setCompany(this.companies[0]);
            }

            // This is buggy!!
            //this.setCompany(this.companies[0]);

            this.loading = false;
            return { code: "SUCCESS", message: "Load complete" };
          } else {
            this.loading = false;
            return { code: "ERROR", message: "No companies found" };
          }
        } else {
          //console.dir(result);
          this.companies = [];
          this.loading = false;
          return { code: "ERROR", message: "Error while loading companies" };
        }
      } catch (e) {
        //console.dir(e);
        this.loading = false;
        return { code: "ERROR", message: e.message + " (" + e.code + ")" };
      }
    },
    resetData() {
      this.companies = [];
      this.selected = {};
      this.loading = false;
      this.lastChange = null;
    },
    async reloadData() {
      await this.getData(true);
    },
    setCompany(company) {
      this.selected = company;
      useEmployeeStore().autoSelectFirstEmployeeUsingLetters();
    },
  },
});
