import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

//import { useCompanyStore } from "./company";
import { useEmployeeStore } from "../stores/employees";

// GraphQL
import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "../graphql/client";
import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";
//import { useNow } from "@vueuse/core";

provideApolloClient(apolloClient);

// Upload a single document
const { mutate: uploadeHrDocument } = useMutation(
  gql`
    mutation uploadSalaryFile($data: HrDocumentCreateInput!) {
      createHrDocument(data: $data) {
        id
      }
    }
  `
);

// Upload a single document
const { mutate: updateHrDocuments } = useMutation(
  gql`
    mutation UpdateHrDocuments($data: [HrDocumentUpdateArgs!]!) {
      updateHrDocuments(data: $data) {
        id
      }
    }
  `
);

export const useHrDocumentStore = defineStore("hrDocument", {
  state: () => ({
    documents: useStorage("hrdocuments", []),
    newDocuments: [],
    loading: false,
  }),
  getters: {
    allNewDocuments: (state) => state.newDocuments || [],
    checkIfNewDocumentsAreComplete: (state) => {
      let complete = true;
      state.newDocuments.forEach((doc) => {
        //console.log("doc", doc);
        Object.values(doc.data).some((value) => {
          if (!value) {
            complete = false;
          }
        });
      });
      return complete;
    },
    realNumberOfNewDocuments: (state) => {
      let count = 0;
      state.documents.forEach((doc) => {
        if (doc.document) {
          count += doc.document.length;
        }
      });
      return count;
    },
    documentsWithoutType: (state) => {
      return state.documents.map((doc) => doc.document).flat();
    },
  },
  actions: {
    async getDocuments() {
      if (this.loading) return;

      const employeeStore = useEmployeeStore();
      if (!employeeStore.selectedEmployee?.id) {
        this.documents = [];
        return { code: "ERROR", message: "noEmployeeSelected" };
      }

      this.documents = [];
      this.loading = true;
      try {
        // use apollo client directly
        const result = await apolloClient.query({
          query: gql`
            query ($employeeId: ID) {
              hrDocumentTypes(orderBy: { manualOrder: asc }) {
                id
                name
                isRequired
                expirationDateType
                appShowExpiringInfoInDays
                document(where: { employee: { id: { equals: $employeeId } } }) {
                  id
                  attachment {
                    filename
                    filesize
                  }
                  requestedRemoval
                  needsReview
                  expirationDate
                  createdAt
                  updateAt
                }
              }
            }
          `,
          variables: { employeeId: employeeStore.selectedEmployee.id },
          fetchPolicy: "no-cache",
        });
        //console.dir(result);
        if (result.data && result.data.hrDocumentTypes) {
          if (result.data.hrDocumentTypes.length > 0) {
            this.documents = result.data.hrDocumentTypes;
            this.loading = false;
            return { code: "SUCCESS", message: "Load complete" };
          } else {
            this.documents = [];
            this.loading = false;
            return { code: "SUCCESS", message: "No documents found" };
          }
        } else {
          this.loading = false;
          return { code: "ERROR", message: "Error while loading documents" };
        }
      } catch (e) {
        this.loading = false;
        return { code: "ERROR", message: e.message + " (" + e.code + ")" };
      }
    },
    addNewDocuments(files, userId, userName) {
      let skippedFilesWithNames = [];
      for (let i = 0; i < files.length; i++) {
        const newDoc = {
          id: files[i].name + files[i].size + files[i].lastModified,
          data: {
            file: files[i],
            /*expiration: null,*/
            type: null,
            employee: userId,
          },
          meta: {
            expirationDateType: "disabled",
            employeeName: userName,
          },
        };
        // Only add if file is not already in the list (checking filename, size and lastModified)
        const alreadyAdded = this.newDocuments.filter((doc) => doc.id === newDoc.id);
        //console.log("alreadyAdded", alreadyAdded);
        //console.log(newDoc.id);
        if (!typeof alreadyAdded === "undefined" || alreadyAdded.length === 0) {
          this.newDocuments.push(newDoc);
        } else {
          skippedFilesWithNames.push(files[i].name);
        }
      }
      return skippedFilesWithNames;
    },
    updateDataOnKeyForNewDocumentUsingId(id, data, key) {
      const doc = this.newDocuments.find((doc) => doc.id === id);
      if (doc) {
        if (data === null) {
          delete doc.data[key];
        } else {
          doc.data[key] = data;
        }
        /*
        if (key == "type") {
          doc.meta.expirationIsRequired = isDateRequired;
          if (isDateRequired == "true") {
            doc.data.expiration = null;
          } else {
            delete doc.data.expiration;
          }
        }
        */
      }
    },
    updateMetaOnKeyForNewDocumentUsingId(id, data, key) {
      const doc = this.newDocuments.find((doc) => doc.id === id);
      if (doc) {
        doc.meta[key] = data;
      }
    },
    getValueForNewDocumentUsingId(id, key) {
      const doc = this.newDocuments.find((doc) => doc.id === id);
      if (doc) {
        return doc.data[key];
      } else {
        return null;
      }
    },
    getMetaValueForNewDocumentUsingId(id, key) {
      const doc = this.newDocuments.find((doc) => doc.id === id);
      if (doc) {
        return doc.meta[key];
      } else {
        return null;
      }
    },
    removeNewFiles(files) {
      for (let i = 0; i < files.length; i++) {
        const docIndex = this.newDocuments.findIndex((doc) => doc.id === files[i]);
        if (docIndex >= 0) {
          this.newDocuments.splice(docIndex, 1);
        }
      }
    },
    async uploadNewDocuments() {
      if (!this.checkIfNewDocumentsAreComplete) return;
      let uploadedIds = [];
      let errors = [];

      // Upload single file after another
      for (let i = 0; i < this.newDocuments.length; i++) {
        const element = this.newDocuments[i];
        const mutationData = {
          attachment: { upload: element.data.file },
          expirationDate: element.data.expiration || null,
          employee: { connect: { id: element.data.employee } },
          type: { connect: { id: element.data.type } },
        };
        try {
          const result = await uploadeHrDocument({ data: mutationData });
          //console.log(result);
          if (result.data && result.data.createHrDocument && result.data.createHrDocument.id) {
            uploadedIds.push(element.id);
          } else if (result.errors) {
            console.log("Error while uploading document");
            console.dir(result);
            errors.push(result.errors[0].message);
          }
        } catch (e) {
          console.log("Document Upload Exception");
          console.dir(e);
          errors.push(e.message);
        }
      }
      // Remove files that were successfully uploaded
      //console.log("uploadedIndexes (needed to remove)", uploadedIds);

      if (uploadedIds.length > 0) {
        this.removeNewFiles(uploadedIds);

        // Update Store with new data
        await this.getDocuments();
      }

      return { uploadedIds, errors };
    },
    async requestRemovalOfDocuments(documentIds) {
      //console.log("requestRemovalOfDocuments", documentIds);

      const mutationData = documentIds.map((id) => {
        return { where: { id }, data: { requestedRemoval: true } };
      });

      //console.log("mutation data", mutationData);

      let updatedIds = [];
      let errors = [];

      try {
        const result = await updateHrDocuments({ data: mutationData });
        //console.log(result);
        if (result.data && result.data.updateHrDocuments) {
          //console.log(result.data.updateHrDocuments);
          updatedIds.push(result.data.updateHrDocuments);
        } else if (result.errors) {
          //console.log("Error while updating document");
          //console.dir(result);
          errors.push(result.errors[0].message);
        }
      } catch (e) {
        //console.log("Document Update Exception");
        //console.dir(e);
        errors.push(e.message);
      }

      return { updatedIds, errors };
    },
    async approveDocument(documentId) {
      const mutationData = {
        where: { id: documentId },
        data: { needsReview: false },
      };

      let updatedIds = [];
      let errors = [];

      try {
        const result = await updateHrDocuments({ data: mutationData });
        //console.log(result);
        if (result.data && result.data.updateHrDocuments) {
          //console.log(result.data.updateHrDocuments);
          updatedIds.push(result.data.updateHrDocuments);
        } else if (result.errors) {
          //console.log("Error while updating document");
          //console.dir(result);
          errors.push(result.errors[0].message);
        }
      } catch (e) {
        //console.log("Document Update Exception");
        //console.dir(e);
        errors.push(e.message);
      }

      return { updatedIds, errors };
    },
    resetData() {
      this.documents = [];
      this.newDocuments = [];
      this.loading = false;
    },
  },
});
