import { useNow, useDateFormat } from "@vueuse/core";
import { addDays } from "date-fns";

/**
 * Update date based on months (add or subtract)
 *
 * @param {Date} date
 * @param {Number} months
 * @returns {Date} NewDate
 */
export function addMonths(date, months) {
	var d = date.getDate();
	date.setMonth(date.getMonth() + months);
	if (date.getDate() != d) {
		date.setDate(0);
	}
	return date;
}

/**
 * List period in time selection
 *
 * Return current month and previous 3 months plus one month in the future
 * @returns Array of objects with name and value
 */
export function makeDateArray() {
	const dateArray = [];
	const currentY = useDateFormat(useNow(), "YYYY").value;
	const currentM = useDateFormat(useNow(), "M").value;
	const currentD = 1;

	const currentMonth = new Date(currentY, currentM - 1, currentD);

	for (let i = 1; i >= -12; i--) {
		const month = new Date(currentMonth.getTime());
		dateArray.push({
			name: useDateFormat(addMonths(month, i), "MM (MMM) YYYY").value,
			value: useDateFormat(month, "YYYY-MM-DD").value,
		});
	}

	return dateArray;
}

/**
 * Check if Date part of filename structure and return date
 *
 * @param {string} date: In the format MYYYY or MMYYYY
 * @returns
 */
export function makeDateFromFileNameDate(date) {
	let dateString = null;
	if (date.length === 4) {
		dateString = `${date}-01-01`;
	} else if (date.length === 5) {
		const month = date.substring(1, 0);
		const year = date.substring(1, 5);
		dateString = `${year}-0${month}-01`;
	} else if (date.length === 6) {
		const month = date.substring(0, 2);
		const year = date.substring(2, 6);
		dateString = `${year}-${month}-01`;
	}
	return dateString;
}

export function isDateValid(dateStr) {
	if (dateStr === null) return false;
	const date = new Date(dateStr);
	return date instanceof Date && !isNaN(date);
}

export function formatDate(date) {
	if (date) {
		return useDateFormat(new Date(date), "D.M.YYYY", { locales: "de" }).value;
	}
	return "-";
}

export function formatDateFull(date) {
	if (date) {
		return useDateFormat(new Date(date), "DD.MM.YYYY HH:mm:ss", {
			locales: "de",
		}).value;
	}
	return "-";
}

export function fullYearOfDate(date) {
	if (date) {
		return useDateFormat(new Date(date), "YYYY", { locales: "de" }).value;
	}
	return "-";
}

export function formatDateAsPeriod(date) {
	if (date) {
		return useDateFormat(new Date(date), "MM.YYYY", { locales: "de" }).value;
	}
	return "-";
}

export function getAddedFormat(date) {
	return useDateFormat(date, "D.M.YYYY", { locales: "de" }).value;
}

export function getExpiresFormat(date) {
	if (date) {
		return useDateFormat(new Date(date), "D.M.YYYY", { locales: "de" }).value;
	}
	return "-";
}

export function isFileExpired(date) {
	if (date) {
		const today = new Date();
		const expirationDate = new Date(date);
		//console.log(today, expirationDate);
		return expirationDate.getTime() < today.getTime();
	}
	return false;
}

export function isExpiring(daysInAdvance, date) {
	if (date) {
		const expirationDate = new Date(date);
		const daysFromNow = addDays(new Date(), daysInAdvance);

		return expirationDate.getTime() < daysFromNow.getTime();
	}
	return false;
}
