export default {
	en: {
		short: {
			year: "numeric",
			month: "numeric",
		},
		long: {
			year: "numeric",
			month: "numeric",
			day: "numeric",
		},
	},
	fr: {
		short: {
			year: "numeric",
			month: "numeric",
		},
		long: {
			year: "numeric",
			month: "numeric",
			day: "numeric",
		},
	},
};
