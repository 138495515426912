import { defineStore } from "pinia";

import { useAuthStore } from "./auth";
import { useCompanyStore } from "./company";
import { useEmployeeStore } from "./employees";
import { useUserContextStore } from "./usercontext";

// main is the name of the store. It is unique across your application
// and will appear in devtools
export const useSearchStore = defineStore("search", {
  // a function that returns a fresh state
  state: () => ({
    financeSearch: "",
    hrSearch: "",
  }),
  // optional getters
  getters: {
    getHrSearchQuery: (state) => state.hrSearch,
    getFinanceSearchQuery: (state) => state.financeSearch,
    /* not used
    getHrCompanyFilter: (state) => {
      const authStore = useAuthStore();

      let companyFilter = {};

      // If employee profile is active, filter by company
      if (authStore.selectedEmployee && authStore.selectedEmployee.id) {
        if (authStore.selectedEmployee.role.canManageHumanResources) {
          companyFilter = {
            company: { id: { equals: authStore.selectedEmployee.company.id } },
          };
        }
      }

      return companyFilter;
    },
    */
    /*
     *  Construct a filter for the finance search. Must return AccountingDocumentWhereInput!
     */
    getAccountingFilter(state) {
      //console.log("getAccountingFilter", this);
      return { where: { ...this.getCompanyFilter } };
    },

    getCompanyFilter: (state) => {
      //const authStore = useAuthStore();
      const contextStore = useUserContextStore();
      //const companyStore = useCompanyStore();

      let companyFilter = { company: {} };

      // Check if Employee Profile is active
      /*
      if (authStore.selectedEmployee && authStore.selectedEmployee.id) {
        //console.log("Client/Employee Level Context Filter active");
        companyFilter = {
          company: { id: { equals: authStore.selectedEmployee.company.id } },
        };
      } else if (companyStore.selectedCompanyId) {
        //console.log("Account Level context Filter active");
        companyFilter = {
          company: { id: { equals: companyStore.selectedCompanyId } },
        };
      }
      */

      companyFilter = {
        company: { id: { equals: contextStore.company.id } },
      };

      //console.dir(companyFilter, { depth: null });
      return companyFilter;
    },
  },
  actions: {},
});
