import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

import { useCompanyStore } from "./company";
import { useAuthStore } from "./auth";

// GraphQL
import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "../graphql/client";
import gql from "graphql-tag";

provideApolloClient(apolloClient);

export const useEmployeeStore = defineStore("employees", {
  state: () => ({
    allEmployees: useStorage("employeesAllEmployees", []),
    selected: useStorage("employeesSelected", {}),
    lastSelected: {},
    loading: false,
    lastChange: useStorage("employeesLastChange", {}),
  }),
  getters: {
    employees: (state) => {
      //if (useAuthStore().account.role?.canAccessAllCompanies) {
      //return state.allEmployees;
      return state.allEmployees.filter((e) => e.company.id === useCompanyStore().selectedCompanyId) || [];
      //} else {
      return state.allEmployees.filter((e) => e.company.id === useAuthStore().selectedCompany) || [];
      //}
    },
    employeesByLetter: (state) => {
      return (letter) => state.employees.filter((e) => e.letter === letter);
    },
    employeesLetters() {
      if (this.employees.length > 0) {
        return [...new Set(this.employees.map((employee) => employee.letter).sort())];
      } else {
        return [];
      }
    },
    selectedEmployee: (state) => {
      if (useAuthStore().account.role?.canAccessAllCompanies) {
        return state.lastSelected;
      } else if (state.allEmployees.length === 1) {
        return state.allEmployees[0];
      } else if (state.allEmployees.length > 1) {
        return state.selected[useAuthStore().selectedCompany] ?? undefined;
      } else {
        return {};
      }
    },
    isSelectedAvailable(state) {
      if (this.employees.filter((employee) => employee.id === state.selected.id).length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Pass in employee code and company code and return employee id
     * @param {code, companycode} state
     * @returns id of employee
     */
    employeeUsingCodes: (state) => (code, companycode) => {
      /*
      let id = null;

      return state.allEmployees.find((e) => e.code === code && e.company.code === companycode);
      if (employee) {
        id = employee.id;
      }

      return id;
      */

      const employee = state.allEmployees.find((e) => e.code === code && e.company.code === companycode);
      return employee ? employee : null;
    },
    allEmployeesReturnEmployeeUsingId: (state) => (userid) => {
      let employee = null;

      const find = state.allEmployees.find((e) => e.id === userid);
      if (find) {
        employee = find;
      }
      /*
      for (const [key, value] of Object.entries(state.allEmployees)) {
        console.dir(toRaw(value));
        const companyEmployees = toRaw(value);
        //console.log(companyEmployees);

        // Only works correctly if user is allocated to one company
        const employeeMatch = companyEmployees.find((e) => e.id === userid);
        if (employeeMatch) {
          employee = employeeMatch;
          break;
        }
      }
      */

      return employee;
    },
  },
  actions: {
    async getData() {
      try {
        if (this.loading) return;
        this.loading = true;

        //const searchStore = useSearchStore();
        //console.dir(searchStore.getHrCompanyFilter);

        let variablesWhere = {};

        // use apollo client directly
        const result = await apolloClient.query({
          query: gql`
            query Query($orderBy: [CompanyEmployeeOrderByInput!]!, $where: CompanyEmployeeWhereInput!) {
              companyEmployees(orderBy: $orderBy, where: $where) {
                id
                code
                name
                jobDescription
                hrDocumentNotification
                letter
                settingHrDocumentsDisabled
                user {
                  emailHash
                }
                company {
                  id
                  code
                  name
                }
              }
            }
          `,
          variables: {
            orderBy: {
              name: "asc",
            },
            where: variablesWhere,
          },
          fetchPolicy: "no-cache",
        });
        //console.dir(result);
        if (result.data && result.data.companyEmployees) {
          if (result.data.companyEmployees.length > 0) {
            this.allEmployees = result.data.companyEmployees;
            this.autoSelectFirstEmployeeUsingLetters();
            this.loading = false;
            return { code: "SUCCESS", message: "Load complete" };
          } else {
            this.documents = [];
            this.loading = false;
            return { code: "SUCCESS", message: "No employees found" };
          }
        } else {
          //console.dir(result);
          this.loading = false;
          return { code: "ERROR", message: "Error while loading employees" };
        }
      } catch (e) {
        //console.dir(e);
        this.loading = false;
        return { code: "ERROR", message: e.message + " (" + e.code + ")" };
      }
    },
    resetData() {
      this.allEmployees = [];
      this.selected = {};
      this.lastSelected = {};
      this.loading = false;
      this.lastChange = {};
    },
    setEmployee(employee) {
      if (!employee) return;

      if (employee.id && this.lastSelected.id !== employee.id) {
        this.lastSelected = employee;
        this.selected[employee.company.id] = employee;
      }
    },
    autoSelectFirstEmployeeUsingLetters() {
      const letter = this.employeesLetters[0];
      const eByLetter = this.employeesByLetter(letter);

      if (eByLetter.length > 0 && eByLetter[0]) {
        this.setEmployee(eByLetter[0]);
      }
    },
  },
});
