import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";

// New Link for Uploads
import { createUploadLink } from "apollo-upload-client";

import { useAuthManager } from "../composables/authManager";
const { userManager } = useAuthManager();

const httpUploadLink = createUploadLink({
  uri: import.meta.env.VITE_BACKEND_BASE_URL + import.meta.env.VITE_BACKEND_GRAPHQL_ENDPOINT,
  credentials: "include",
  headers: { "Apollo-Require-Preflight": "true" },
});

// Add the authorization to the headers
const authLink = setContext(async (_, { headers }) => {
  try {
    const auth = await userManager.getUser();
    return {
      headers: {
        ...headers,
        authorization: auth.access_token ? `Bearer ${auth.access_token}` : "",
      },
    };
  } catch (error) {
    return {
      headers: {
        ...headers,
        authorization: "",
      },
    };
  }
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },

  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },

  mutate: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  link: authLink.concat(httpUploadLink),
  cache,
  connectToDevTools: true,
  defaultOptions,
});
export default apolloClient;
